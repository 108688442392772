import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import axios from 'axios'

import PrimaryButton from "../Button/PrimaryButton";
import LineArrowRight from "../Icons/LineArrowRight"
import { Checkbox, HelpText } from "../Section";

const FormWrap = styled.form`
	padding: 40px 30px;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  margin-bottom: 72px;

  .title {
    font-size: 26px;
    font-family: "Eina03-Bold";
    line-height: 24px;
    margin-bottom: 29px;
  }

  input {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .1) !important;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1;
    padding: 16px 20px 19px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
  textarea {
    width: 100%;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .1) !important;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1;
    padding: 16px 20px 19px;
    resize: none;
    &:focus {
      outline: none;
    }
  }

`

class GetCustomQuoteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      full_name: '',
      email: '',
      phone: '',
      zipcode: '',
      comment: '',
      agreeViaSms: true,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
		if (typeof window !== 'undefined') 
		{
			const searchParams = new URLSearchParams(window.location.search);
			if(searchParams.get("utm_source")!='' && searchParams.get("utm_source")!=null)
			{
				sessionStorage.setItem('utm_source', searchParams.get("utm_source"));
				sessionStorage.setItem('utm_medium', searchParams.get("utm_medium"));
				sessionStorage.setItem('utm_campaign', searchParams.get("utm_campaign"));
				sessionStorage.setItem('utm_term', searchParams.get("utm_term"));
				sessionStorage.setItem('utm_content', searchParams.get("utm_content"));
			}
		}
	}
  handleChange(event) {
    const {id, value} = event.target
		if (id !== "agreeViaSms") {
		this.setState({
			[id]: value,
		})
		} else {
		this.setState({
			[id]: event.target.checked,
		})
		}
  }
  handleSubmit(event) {
    let utm_source_val = 'website';
		let utm_medium_val = '';
		let utm_campaign_val = '';
		let utm_term_val = '';
		let utm_content_val = '';
		if(sessionStorage.getItem('utm_source')!='' && sessionStorage.getItem('utm_source')!=null) 
		{
      utm_source_val = sessionStorage.getItem('utm_source');
      utm_medium_val = sessionStorage.getItem('utm_medium');
      utm_campaign_val = sessionStorage.getItem('utm_campaign');
      utm_term_val = sessionStorage.getItem('utm_term');
      utm_content_val = sessionStorage.getItem('utm_content');
		}
    event.preventDefault();
    const { location } = this.props
    const data = {
      source: location.origin,
			form: "Custom Quote Form For Each Product",
			page: location.pathname,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone,
      zipcode: this.state.zipcode,
      comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
      agreeViaSms: this.state.agreeViaSms,
      utm_medium: utm_medium_val,
			utm_term: utm_term_val,
			utm_campaign: utm_campaign_val,
			utm_content: utm_content_val,
			lead_source: utm_source_val,
    }
    //console.log("==========", data)
    axios({
      method: "post",
       url: "https://crm.senseicrm.com/api/create-lead-wordpress",
       data: data,
       headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
     }).then((res) => {
       this.setState({ full_name: '', email: '', phone: '', zipcode: '', comment: '', agreeViaSms: false});
       navigate("/thank-you-custom-quote");
     }).catch((error) => {
         alert(error)
     });
  }
  render() {
    return(
    	<FormWrap id="CustomQuoteForm" onSubmit={this.handleSubmit}>
    	  <h3 className="title">Get Your Custom Quote</h3>
    	  <input type="text" name="full_name" id="full_name" onChange={this.handleChange} placeholder="Full Name" pattern="^(?!\s*$)[A-Za-z\s]+$" required />
        <input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email ID" required />
        <input type="tel" name="phone" id="phone" onChange={this.handleChange} placeholder="Contact Number" pattern="[0-9]{10}" required />
        <input type="number" name="zipcode" id="zipcode" onChange={this.handleChange} placeholder="Zip Code" pattern="^[0-9]{5}$" required />
        <textarea rows="3" name="comment" id="comment" onChange={this.handleChange} placeholder="Message" required />
        <div className='one-col'>
					<Checkbox>
						<label>
							<input
							className="form-control"
							type="checkbox"
							id="agreeViaSms"
							name="agreeViaSms"
							defaultChecked={true}
							onChange={this.handleChange}
							aria-label="contacted via SMS"
							/>
							{` `}I agree that I will be contacted via SMS.
						</label>
						</Checkbox>
						<HelpText>By submitting this form, I agree to receive text messages from Buildings And More. Standard messaging rates and data charges may apply. Message frequency may vary. You can opt-out by replying STOP at any time or reply Help to get more information.</HelpText>
					</div>
        <button type='submit'>
          <PrimaryButton text="Submit" icon={<LineArrowRight />} />
        </button>
    	</FormWrap>
    );
  }
}

export default GetCustomQuoteForm;